<template>
    <div>
      
      <section id="lista_dias" class="">
         <div v-for="(evento, index) in eventosDelDia" :key="index">

         <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-5xl m-5">
          <div class="md:flex">
        <div class="md:flex-shrink-0">
         <img :src="require('@/assets/images/' + evento.imagen)" class="h-11/12 w-full object-cover md:w-72" alt="Event image">
              </div>
        <div class="p-4">

         <p class="text-lg font-bold text-black truncate block capitalize">PARROQUIA {{ evento.Nombre  }}</p>
         <div v-for="(detalleEvento, detalleIndex) in evento.eventos" :key="detalleIndex">
        <p class="mt-2 text-gray-500">{{ detalleEvento.nombre }}</p>
         <dl class="mt-2 flex flex-col text-gray-500 xl:flex-row">
               <div class="flex items-start space-x-3">
                  <dt class="mt-0.5">
                     <span class="sr-only">Fecha</span>
                     <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm-.75-9.25a.75.75 0 011.5 0v3.5a.75.75 0 01.75.75h2a.75.75 0 010 1.5h-2.75a.75.75 0 01-.75-.75v-5z" clip-rule="evenodd" />
                     </svg>
                  </dt>
               <dd><time datetime="2022-01-10T17:00">{{  detalleEvento.horarios  }}</time></dd>
            </div>
            <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
              <dt class="mt-0.5">
                <span class="sr-only">Fecha</span>
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z" clip-rule="evenodd" />
                </svg>
              </dt>
              <dd><time datetime="2022-01-10T17:00">{{  evento.dia  }}</time></dd>
            </div>
            <a :href="detalleEvento.ubi" target="_blank">
            <div class="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
              <dt class="mt-0.5">
                <span class="sr-only">Ubicación</span>
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" clip-rule="evenodd" />
                </svg>
              </dt>
              <dd class="font-bold text-black  capitalize">Como llegar?</dd>
              <dd>Acacías, Meta, Colombia</dd>
            </div>
         </a>
           </dl>   
           <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
           </div>
         </div>
        </div>
    </div>
</div>
      </section>
        
    </div>
</template>
<script>
export default {
  name: 'DiaSanto',
  props: {
    cardId: String
  },
  data() {
    return {
      // Aquí es donde integrarás los datos de los eventos
      cards: [
      {
         card1: [
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
           "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "8:00 a.m", "10:00 a.m", "12:0 n no  0 p.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",

       },
       {
         "nombre": "Bautizados y enviados",
         "horarios": ["7:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",

       },
       {
         "nombre": "Bendición de ramos y procesión",
         "horarios": ["9:15 a.m"],
         "ubi":"https://maps.app.goo.gl/CPiuoDNrJyQsyMAT7",

       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",

       },
       {
         "nombre": "Pascua infantil",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua juvenil",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Eucaristía cementerio",
         "horarios": ["5:00 p.m"],
         "ubi":"https://maps.app.goo.gl/9RS3bGMQQqHSUkKK8",
         
       },
       {
         "nombre": "Pascua Familiar",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Familiar",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Eucaristía de unión de los Enfermos",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pascua Familiar",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Eucaristía de la cena del señor",
         "horarios": ["3:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Adoración",
         "horarios": ["5:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Hora Santa",
         "horarios": ["9:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Vía Crucis",
         "horarios": ["5:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Pasión y muerte de nuestro señor Jesucristo",
         "horarios": ["3:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Visita al santo sepulcro",
         "horarios": ["10:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
 "nombre": "Liturgia de la Luz",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Liturgia de la palabra",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Liturgia Bautizmal",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Liturgia de la Eucaristía",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DEL CARMEN",
     "imagen":"iglesia2.jpg",
     "fecha": 31,
     "dia": "Domingo de Resurrección",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "8:00 a.m", "12:00 p.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Programa bautizados y enviados",
         "horarios": ["7:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Procesión con Jesús Resucitado",
         "horarios": ["9:15 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       },
       {
         "nombre": "Eucaristía solemne",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cLuvfDUehaigvpH27",
       }
     ]
   }
 ],
  card2: [
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Eucaristía y bendición de ramos",
         "horarios": ["6:00 a.m", "8:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
         
       },
       {
         "nombre": "Procesión y bendición de ramos",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/cZaT1kfqDDd8MbsN6",
         
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Pascua Infantil",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Eucaristías de los niños nacidos y por nacer",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Ágape con adultos mayores",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Eucaristía por los enfermos",
         "horarios": ["8:00 a.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Cena del Señor",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Adoración ante el monumento",
         "horarios": ["6:00 p.m a 11:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "El santo vía crucis",
         "horarios": ["4:00 a.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Lectura de la pasión y muerte de nuestro señor Jesucristo",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Oración ante el santo sepulcro",
         "horarios": ["6:00 p.m a 11:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Procesión con la dolorosa",
         "horarios": ["5:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Procesión",
         "horarios": ["8:00 p.m"],
         "ubi":"https://maps.app.goo.gl/3wTJxEFTCwyAvagz6",
         
       }
     ]
   },
   {
     "Nombre":"SAGRADO CORAZÓN DE JESÚS",
     "imagen":"iglesia3.jpg",
     "fecha": 31,
     "dia": "Domingo de Resurrección",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["8:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       },
       {
         "nombre": "Procesión con el Resucitado",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/c6cuH8JnCUWHCgnq6",
       }
     ]
   }
 ],
 card3: [
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Santa Misa",
         "horarios": ["6:00 a.m", "10:00 a.m", "12:00 p.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Procesión y bendición de ramos",
         "horarios": ["9:15 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
      "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Encuentro de los niños",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Encuentro de los Jóvenes",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Santa Misa",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Santa misa",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Encuentro de los niños",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Encuentro de los jóvenes",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Santa Misa",
         "horarios": ["6:00 a.m", "6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Encuentro de los niños",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Misa para Enfermos",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Encuentro de Jóvenes",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Santa Misa",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Santo Monumento",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Hora Santa",
         "horarios": ["9:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Santo Vía crucis",
         "horarios": ["5:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Santo Vía crucis con los niños y enfermos",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Celebración de la pasión y muerte del señor",
         "horarios": ["4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Procesión con la dolorosa",
         "horarios": ["5:00 a.m", "4:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Vigilia Pascual",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   },
   {
     "Nombre":"DIVINO NIÑO DE JESÚS",
     "imagen":"iglesia1.jpg",
     "fecha": 31,
     "dia": "Domingo Santo",
     "eventos": [
       {
         "nombre": "Santa Misa",
         "horarios": ["6:00 a.m", "10:00 a.m", "12:00 p.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       },
       {
         "nombre": "Procesión con el Resucitado",
         "horarios": ["9:45 a.m"],
         "ubi":"https://maps.app.goo.gl/HriVJJPuibpr2oWc8",
       }
     ]
   }
 ],
 card4: [
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 a.m", "9:30 a.m", "7:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Liturgia de bendición y solemne procesión de Ramos",
         "horarios": ["11:00 a.m"],
         "ubi":"https://maps.app.goo.gl/CW1exs2A2Nz3mYtn7",
       },
       {
         "nombre": "Solemne misa de Ramos",
         "horarios": ["11:30 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Confesiones",
         "horarios": ["9:00 a.m", "12:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Encuentro con los niños",
         "horarios": ["9:00 a.m", "12:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Retiro de mujeres",
         "horarios": ["6:00 p.m", "9:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Confesiones",
         "horarios": ["9:00 a.m", "12:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Encuentro de jóvenes",
         "horarios": ["9:00 a.m", "12:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Retiro de Hombres",
         "horarios": ["6:00 p.m", "9:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Confesiones",
         "horarios": ["9:00 a.m", "12:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["6:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Eucaristía de oración a los Enfermos",
         "horarios": ["7:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Eucaristía de la cena del señor",
         "horarios": ["3:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Adoración",
         "horarios": ["6:00 p.m", "12:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Mangrullos y catequistas",
         "horarios": ["6:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Legión de María",
         "horarios": ["7:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Sacerdote comunidad",
         "horarios": ["8:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Grupo de servidores",
         "horarios": ["9:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Grupo de Nazarenos",
         "horarios": ["10:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Grupo de ministros",
         "horarios": ["11:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Santo Vía crucis",
         "horarios": ["5:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Vía Crucis en el templo",
         "horarios": ["10:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Pasión y muerte de nuestro señor Jesucristo",
         "horarios": ["3:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Hora de Getsemaní",
         "horarios": ["6:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Sermón de las 7 palabras",
         "horarios": ["7:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Rosario de la dolorosa (solo mujeres) y de San Juan (solo hombres)",
         "horarios": ["7:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Gran vigilia y misa de resurrección",
         "horarios": ["8:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   },
   {
     "Nombre":"JESÚS EL BUEN PASTOR",
     "imagen":"iglesia4.jpg",
     "fecha": 31,
     "dia": "Domingo Santo",
     "eventos": [
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["9:30 a.m", "7:00 p.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Solemne procesión con el Resucitado",
         "horarios": ["11:00 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       },
       {
         "nombre": "Solemne Misa de Pascua",
         "horarios": ["11:30 a.m"],
         "ubi": "https://maps.app.goo.gl/D3stDy7aGXakuDR67"
       }
     ]
   }
 ],
 card5: [
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Bendición de ramos y Eucaristía",
         "horarios": ["06:00 AM", "11:00 AM", "6:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       },
       {
         "nombre": "Eucaristía capilla Rosa Mística",
         "horarios": ["8:00 AM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:30 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Enseñanza, Oración por los enfermos y Eucaristía",
         "horarios": ["6:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Confesiones",
         "horarios": ["4:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       },
       {
         "nombre": "Eucaristía",
         "horarios": ["6:30 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Eucaristía de la cena del Señor",
         "horarios": ["4:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       },
       {
         "nombre": "Capilla rosa mística",
         "horarios": ["6:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Vía Crucis",
         "horarios": ["5:00 AM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       },
       {
         "nombre": "Celebración de la pasión del Señor",
         "horarios": ["3:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Rosario Virgen de los Dolores",
         "horarios": ["7:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       },
       {
         "nombre": "Solemne Vigilia Pascual",
         "horarios": ["6:00 PM"],
         "ubi":"https://maps.app.goo.gl/rhE1BLNyMqAgHEGWA"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE GUADALUPE",
     "imagen":"iglesia5.jpg",
     "fecha": 31,
     "dia": "Domingo de Pascua",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["6:00 PM"],
         "ubi":"https://maps.app.goo.gl/FDuE4614oNSQXWhcA"
       }
     ]
   }
 ],
 card6: [
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Eucaristía. Bendición de Ramos y Arbolitos",
         "horarios": ["7:00 a.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía y Procesión de Ramos",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/CSo9uc5CLev26SCo8"
         
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:30 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["2:30 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía. Bendición de las familias",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Película 'LA RESURRECCIÓN DE CRISTO'",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["7:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:30 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["2:30 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía. Bendición de las familias",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "CINEFORO. Documental cinematográfico",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["7:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:30 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía por los Enfermos",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["2:30 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía Familiar",
         "horarios": ["6:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "CINEFORO. Documental cinematográfico",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Eucaristía Institución del Sacramento de la Sagrada Eucaristía, del Orden Sacerdotal, del Mandamiento del Amor, y Procesión al Santo Monumento",
         "horarios": ["5:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Hora Santa del Santo Sacramento",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Santo vía crucis",
         "horarios": ["8:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Santo vía crucis para los enfermos y quienes no puedan asistir caminando",
         "horarios": ["10:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Celebración Liturgia",
         "horarios": ["5:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Santo Rosario",
         "horarios": ["8:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Sagrada Vigilia Pascual",
         "horarios": ["7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   },
   {
     "Nombre":"NUESTRA SEÑORA DE LA PAZ",
     "imagen":"iglesia6.jpg",
     "fecha": 31,
     "dia": "Domingo Santo",
     "eventos": [
       {
         "nombre": "Eucaristía",
         "horarios": ["7:00 a.m", "11:00 a.m", "5:00 p.m", "7:00 p.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Eucaristía de Resurrección",
         "horarios": ["9:00 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       },
       {
         "nombre": "Solemne Misa de Pascua",
         "horarios": ["11:30 a.m"],
         "ubi":"https://maps.app.goo.gl/UL55MYy4pMHMLdCK9"
       }
     ]
   }
 ],
 card7: [
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 24,
     "dia": "Domingo de Ramos",
     "eventos": [
       {
         "nombre": "Bendición y procesión de ramos",
         "horarios": ["5:30 am"],
         "ubi":"https://maps.app.goo.gl/oisjJ5yjZJ1UNkwS6",
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 am", "9:00 am", "11:00 am", "5:00 pm", "7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 25,
     "dia": "Lunes Santo",
     "eventos": [
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:00 am-11:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Confesiones",
         "horarios": ["10:00 am-12:00 m", "3:00 pm-5:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["4:00 pm-6:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["5:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Retiro Kerigmático",
         "horarios": ["6:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 26,
     "dia": "Martes Santo",
     "eventos": [
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:00 am-11:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["4:00 pm-6:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["5:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Retiro Kerigmático",
         "horarios": ["6:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 27,
     "dia": "Miércoles Santo",
     "eventos": [
       {
         "nombre": "Eucaristía por los Enfermos",
         "horarios": ["9:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Infantil",
         "horarios": ["9:00 am-11:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Confesiones",
         "horarios": ["10:00 am-12:00 pm", "3:00 pm-5:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Pascua Juvenil",
         "horarios": ["4:00 pm-6:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["5:00 pm", "7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 28,
     "dia": "Jueves Santo",
     "eventos": [
       {
         "nombre": "Día de la Caridad",
         "horarios": ["9:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Misa vespertina de la cena del Señor",
         "horarios": ["3:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Adoración proclamando al Santísimo",
         "horarios": ["7:00 pm-5:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 29,
     "dia": "Viernes Santo",
     "eventos": [
       {
         "nombre": "Santo Vía crucis",
         "horarios": ["5:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Celebración de la Pasión del Señor",
         "horarios": ["3:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Sermón de las 7 Palabras",
         "horarios": ["3:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Exclavación del Señor",
         "horarios": ["3:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Procesión al Santo Sepulcro",
         "horarios": ["3:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 30,
     "dia": "Sábado Santo",
     "eventos": [
       {
         "nombre": "Procesión de la Dolorosa",
         "horarios": ["5:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Solemne Vigilia Pascual",
         "horarios": ["7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Bendición del Fuego",
         "horarios": ["7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Bendición del Agua",
         "horarios": ["7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   },
   {
     "Nombre":"MARIA AUXILIADORA",
     "imagen":"iglesia7.jpg",
     "fecha": 31,
     "dia": "Domingo Santo",
     "eventos": [
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 am", "11:00 am", "5:00 pm", "7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Procesión con el Resucitado",
         "horarios": ["8:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["9:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["11:00 am"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["5:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       },
       {
         "nombre": "Santa Eucaristía",
         "horarios": ["7:00 pm"],
         "ubi":"https://maps.app.goo.gl/W7hQsFVzaYzgmkCP9"
       }
     ]
   }
 ],
 card8: [
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 24,
      "dia": "Domingo de Ramos",
      "eventos": [
        {
          "nombre": "Procesión y bendición de ramos en el templo",
          "horarios": ["7:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Concentración en el parque del Barrio la Esperanza",
          "horarios": ["9:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Solemne Eucaristía",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía en la colonia penal",
          "horarios": ["12:00 m."],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía vereda centro",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Procesión y bendición de ramos en el templo",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 25,
      "dia": "Lunes Santo",
      "eventos": [
        {
          "nombre": "Catequesis triduo pascual en la cárcel",
          "horarios": ["8:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Colonia pastor justicia y libertad",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua Infantil",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua Juvenil",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Catequesis de preparación del triduo pascual",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 26,
      "dia": "Martes Santo",
      "eventos": [
        {
          "nombre": "Catequesis triduo pascual",
          "horarios": ["8:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Colonia pastor justicia",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua infantil",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua Juvenil",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Confesiones",
          "horarios": ["3:00 p.m", "5:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía por las familias",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Acompañan niños de primera Comunión",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 27,
      "dia": "Miércoles Santo",
      "eventos": [
        {
          "nombre": "Preparación triduo pascual",
          "horarios": ["8:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Colonia agentes del pastor justicia",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua Infantil",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Pascua Juvenil",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Visita a los Enfermos",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía por los Enfermos",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Procesión siete caídas",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 28,
      "dia": "Jueves Santo",
      "eventos": [
        {
          "nombre": "Misa de la cena del señor",
          "horarios": ["8:00 a.m", "4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Celebración simultánea cárcel, colonia",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Eucaristía Cena del Señor",
          "horarios": ["11:00 a.m"],
          "ubi":"https://maps.app.goo.gl/VtmKLP1bkD7YChNT6"
        },
        {
          "nombre": "Prendimiento del Señor",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Hora Santa",
          "horarios": ["7:30 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 29,
      "dia": "Viernes Santo",
      "eventos": [
        {
          "nombre": "Santo Viacrucis",
          "horarios": ["5:00 a.m", "8:00 a.m",],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Santo Viacrucis",
          "horarios": ["11:00 a.m"],
          "ubi":"https://maps.app.goo.gl/gFZrR1YHfziMKdjq7"
        },
        {
          "nombre": "Adoración de la Santa Cruz",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Celebración de la Pasión del Señor",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Receso en profundo silencio",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Sermón de las 7 Palabras",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Procesión con el Santo Sepulcro",
          "horarios": ["8:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 30,
      "dia": "Sábado Santo",
      "eventos": [
        {
          "nombre": "Procesión con la Dolorosa",
          "horarios": ["5:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Vigilia Pascual",
          "horarios": ["8:00 a.m", "7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/3zp7L3giVLSgk1Gc6"
        },
        {
          "nombre": "Justicia y libertad",
          "horarios": ["11:30 a.m"],
          "ubi":"https://maps.app.goo.gl/3zp7L3giVLSgk1Gc6"
        },
        {
          "nombre": "Solemne Vigilia Pascual",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia8.jpg",
      "fecha": 31,
      "dia": "Domingo Santo",
      "eventos": [
        {
          "nombre": "Eucaristía",
          "horarios": ["7:00 a.m", "4:00 p.m", "6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        },
        {
          "nombre": "Concentración para la Procesión",
          "horarios": ["9:00 a.m"],
          "ubi":"https://maps.app.goo.gl/9mJ1SGg9WZQ9nTLJ7"
        },
        {
          "nombre": "Solemne Eucaristía Resurrección",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/AFNZCSUjx9TjFn4p8"
        }
      ]
    }
  ],
  card9: [
    {
      "Nombre":"SANTA MARTHA",
     "imagen":"iglesia9.jpg",
      "fecha": 24,
      "dia": "Domingo de Ramos",
      "eventos": [
        {
          "nombre": "Eucaristía. Bendición de Ramos y Arbolitos",
          "horarios": ["6:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía y Procesión de Ramos",
          "horarios": ["8:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía. Bendición de Ramos y Arbolitos",
          "horarios": ["5:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 25,
      "dia": "Lunes Santo",
      "eventos": [
        {
          "nombre": "Encuentro de Niños",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Encuentro de Jóvenes",
          "horarios": ["2:30 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 26,
      "dia": "Martes Santo",
      "eventos": [
        {
          "nombre": "Encuentro de Niños",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Encuentro de Jóvenes",
          "horarios": ["2:30 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía Bendición de las Familias",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 27,
      "dia": "Miércoles Santo",
      "eventos": [
        {
          "nombre": "Encuentro de Niños",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía a los Enfermos",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Encuentro con Jóvenes",
          "horarios": ["2:30 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristías Bendición de las Familias",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 28,
      "dia": "Jueves Santo",
      "eventos": [
        {
          "nombre": "Eucaristía Misa Vespertina",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Hora Santa",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 29,
      "dia": "Viernes Santo",
      "eventos": [
        {
          "nombre": "Santo Viacrucis",
          "horarios": ["5:00 a.m"],
          "ubi":"https://maps.app.goo.gl/xCBPxJoLPTLTFbCR7"
        },
        {
          "nombre": "Santo Viacrucis para los enfermos",
          "horarios": ["9:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Celebración Liturgia de la pasión del Señor",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Sermon de las 7 palabras",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 30,
      "dia": "Sábado Santo",
      "eventos": [
        {
          "nombre": "Santo rosario con la Dolorosa",
          "horarios": ["6:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Santa Vigilia Pascual",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    },
    {
      "Nombre":"NUESTRA SEÑORA DE LA MISERICORDIA",
     "imagen":"iglesia9.jpg",
      "fecha": 31,
      "dia": "Domingo Santo",
      "eventos": [
        {
          "nombre": "Eucaristía",
          "horarios": ["6:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía Resurrección",
          "horarios": ["9:00 a.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["5:00 p.m"],
          "ubi":"https://maps.app.goo.gl/treXopkecnewXZhS7"
        }
      ]
    }
  ],
  card10: [
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 24,
      "dia": "Domingo de Ramos",
      "eventos": [
        {
          "nombre": "Eucaristía y bendición de ramos",
          "horarios": ["7:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Bendición y procesión con los Ramos",
          "horarios": ["9:30 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía y bendición de ramos",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 25,
      "dias": ["Lunes Santo", "Martes Santo"],
      "eventos": [
        {
          "nombre": "Pre pascual infantil",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Confesiones",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Pre pascua Juvenil",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 26,
      "dias": ["Lunes Santo", "Martes Santo"],
      "eventos": [
        {
          "nombre": "Pre pascual infantil",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Confesiones",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Pre pascua Juvenil",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 27,
      "dia": "Miércoles Santo",
      "eventos": [
        {
          "nombre": "Pre pascua infantil",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Pre pascua Juvenil",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía para los Enfermos",
          "horarios": ["6:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 28,
      "dia": "Jueves Santo",
      "eventos": [
        {
          "nombre": "Celebración de la cena del Señor",
          "horarios": ["4:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Hora Santa",
          "horarios": ["7:30 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 29,
      "dia": "Viernes Santo",
      "eventos": [
        {
          "nombre": "Santo Viacrucis",
          "horarios": ["5:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Solemne liturgia de la pasión del señor",
          "horarios": ["3:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Procesión del descendimiento",
          "horarios": ["7:00 p.m"],
          "ubi":"https://maps.app.goo.gl/K5AYDq59GVwypbvu5"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 30,
      "dia": "Sábado Santo",
      "eventos": [
        {
          "nombre": "Rosario de los 7 dolores",
          "horarios": ["6:30 a.m"],
          "ubi":"https://maps.app.goo.gl/c65SjV2K3aUhJm4GA"
        },
        {
          "nombre": "Procesión de la Dolorosa",
          "horarios": ["8:00 p.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    },
    {
      "Nombre":"CRISTO REY",
     "imagen":"iglesia10.jpg",
      "fecha": 31,
      "dia": "Domingo Santo",
      "eventos": [
        {
          "nombre": "Eucaristía",
          "horarios": ["7:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Procesión del resucitado",
          "horarios": ["9:15 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        },
        {
          "nombre": "Eucaristía",
          "horarios": ["10:00 a.m"],
          "ubi":"https://maps.app.goo.gl/LBKNnsTv5mVbA1YG8"
        }
      ]
    }
  ],
  card11: [
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 24,
      "dia": "Domingo Santo",
      "eventos": [
        {
          "nombre": "Bendición de Ramos y misa",
          "horarios": ["06:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa Misa",
          "horarios": ["08:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Procesión y bendición de ramos",
          "horarios": ["09:45 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa misa",
          "horarios": ["10:30 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },

        {
          "nombre": "Procesión y bendición de ramos",
          "horarios": ["12:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa misa y bendición de ramos",
          "horarios": ["06:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Procesión",
          "horarios": ["07:30 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }

      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 25,
      "dia": "Lunes Santo",
      "eventos": [
        {
          "nombre": "Confesiones",
          "horarios": ["05:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa Misa",
          "horarios": ["06:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 26,
      "dia": "Martes Santo",
      "eventos": [
        {
          "nombre": "Confesiones",
          "horarios": ["05:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa Misa por los enfermos",
          "horarios": ["06:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 27,
      "dia": "Miércoles Santo",
      "eventos": [
        {
          "nombre": "Confesiones",
          "horarios": ["05:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa Misa por los enfermos",
          "horarios": ["06:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 28,
      "dia": "Jueves Santo",
      "eventos": [
        {
          "nombre": "Celebración de la última Cena",
          "horarios": ["12:00 p.m", "03:00 p.m", "05:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 29,
      "dia": "Viernes Santo",
      "eventos": [
        {
          "nombre": "Santo Viacrucis",
          "horarios": ["05:00 a.m", "09:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Celebración de la Pasión de Cristo",
          "horarios": ["03:00 p.m", "05:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 30,
      "dia": "Sábado Santo",
      "eventos": [
        {
          "nombre": "Procesión con la Dolorosa",
          "horarios": ["06:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Solemne vigilia Pascual",
          "horarios": ["03:00 p.m", "06:00 p.m", "08:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    },
    {
      "Nombre":"SANTIAGO APOSTOL",
     "imagen":"iglesia11.jpg",
      "fecha": 31,
      "dia": "Domingo de Pascua",
      "eventos": [
        {
          "nombre": "Santa Misa",
          "horarios": ["08:00 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Procesión con el resucitado y Santa María",
          "horarios": ["09:45 a.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Santa Misa de resurrección",
          "horarios": ["12:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        },
        {
          "nombre": "Solemne eucaristía de resurrección",
          "horarios": ["06:00 p.m"],
          "ubi":"https://maps.app.goo.gl/b29C5RKbPxFsgTsN6"
        }
      ]
    }
  ]
 

}


        // Inserta aquí la estructura JSON de los eventos
      ]
    };
  },
  methods: {
  abrirComoLlegar(ubi) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const originLat = position.coords.latitude;
        const originLon = position.coords.longitude;
        
        // Dividir 'ubi' para obtener latitud y longitud
        const [destinationLat, destinationLon] = ubi.split(", ");

        const googleMapsURL = `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLon}&destination=${destinationLat},${destinationLon}&travelmode=driving`;

        window.open(googleMapsURL, '_blank');
      }, (error) => {
        alert('Error al obtener la ubicación: ' + error.message);
      });
    } else {
      alert('La Geolocalización no está soportada por este navegador.');
    }
  }
},
  computed: {
    eventosDelDia() {
      const cardKey = this.cardId; // 'cardId' puede ser 'card1', 'card2', etc.
    let indice;
    switch (cardKey) {
  case 'card1':
    indice = 24;
    break;
  case 'card2':
    indice = 25;
    break;
  case 'card3':
    indice = 26;
    break;
  case 'card4':
    indice = 27;
    break;
  case 'card5':
    indice = 28;
    break;
  case 'card6':
    indice = 29;
    break;
  case 'card7':
    indice = 30;
    break;
  case 'card8':
    indice = 31;
    break;
  default:
    console.log('Card no reconocida');
    // Puedes manejar el caso de que cardKey no coincida con ningún caso aquí
    // Por ejemplo, puedes asignar un valor predeterminado a indice o manejar un error
}
console.log(indice);

    let eventosEncontrados = [];

// 'cardArrays' es un objeto que contiene arreglos como 'card1', 'card2', etc.
const cardArrays = this.cards;

// Itera sobre cada propiedad en 'cardArrays'
for (const key in cardArrays) {
  if (Object.hasOwnProperty.call(cardArrays, key)) {
    const cardArray = cardArrays[key];
      Object.entries(cardArray).forEach(([key, value]) => {
         console.log(key, value);
         value.forEach(evento => {
    // 'evento' es cada objeto de evento dentro de la tarjeta (card1, card2, etc.)
    if (evento.fecha === indice) {
      // Aquí es donde coinciden las fechas. Puedes hacer lo que necesites con estos eventos.
      // Si quieres colectar estos eventos, puedes agregarlos a un arreglo, por ejemplo:
      eventosEncontrados.push(evento);
    }
  });
      });

  }
}
console.log('Eventos Encontrados:', eventosEncontrados);

return eventosEncontrados;


    }
  }
  // Puedes añadir métodos, computed properties, etc., para trabajar con los datos
}
</script>
