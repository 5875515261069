<template>
  <div >
    <div class="mb-4">

<img src="@/assets/images/titulo.png" alt="Semana Santa" class="mx-auto w-1/2 h-auto"> <!-- Ajusta el tamaño según necesites -->
</div>
    <HelloWorld msg="Welcome to Your Vue.js App"/>

  </div>
  <div class="">        <!-- Asegúrate de reemplazar las rutas de las imágenes según corresponda -->
  
    <img src="@/assets/images/logo.png" alt="Descripción de la imagen 1" class="z-10 absolute -bottom-100 left-1/4 w-1/2   flex justify-between px-4">
    <img src="@/assets/images/bonita.png" alt="Descripción de la imagen bonita" class="z-10 absolute -bottom-100 right-1   w-1/4   flex justify-between px-4">

    </div>
   <footer >
      <!-- Contenido previo del footer aquí -->

      <!-- Imagen en el footer -->
      <img src="@/assets/images/footer.png" alt="Descripción de la imagen" class="bg-no-repeat bg-opacity-100">

      <!-- Más contenido del footer si es necesario -->
  </footer>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    HelloWorld
    }
}
</script>