<template>
  <div class="hello " >
    



<!-- ✅ Grid Section - Starts Here 👇 -->
<section v-if="selectedCard == null" id="Projects"
      :class="selectedCard ? 'w-fit mx-auto grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 justify-items-center  justify-center gap-y-20 gap-x-14 mt-10 mb-5' : 'w-fit mx-auto grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5'">
    <!--   ✅ Product card 1 - Starts Here 👇 -->
    <div id="card1" @click="selectCard('card1')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card1' || selectedCard === null">       
      <a href="#">
            <img src="../assets/images/domingo_ramos.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">24 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 1 - Ends Here  -->

    <!--   ✅ Product card 2 - Starts Here 👇 -->
    <div id="card2" @click="selectCard('card2')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card2' || selectedCard === null">              
      <a href="#">
          <img src="../assets/images/lunes_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">25 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 2- Ends Here  -->

    <!--   ✅ Product card 3 - Starts Here 👇 -->
    <div id="card3" @click="selectCard('card3')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card3' || selectedCard === null">       
    <a href="#">
          <img src="../assets/images/martes_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">26 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 3 - Ends Here  -->

    <!--   ✅ Product card 4 - Starts Here 👇 -->
    <div id="card4" @click="selectCard('card4')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card4' || selectedCard === null">          
      <a href="#">
          <img src="../assets/images/miercoles_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">27 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 4 - Ends Here  -->

    <!--   ✅ Product card 5 - Starts Here 👇 -->
    <div id="card5" @click="selectCard('card5')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card5' || selectedCard === null">         
      <a href="#">
          <img src="../assets/images/jueves_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">28 de Marzo</span>

                <p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
                <div class="flex items-center">
                    
                    <div class="flex justify-end">
                      <img src="../assets/images/calendario.png" class="w-1/4 "/>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 5 - Ends Here  -->

    <!--   ✅ Product card 6 - Starts Here 👇 -->
    <div id="card6" @click="selectCard('card6')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card6' || selectedCard === null">       

      <a href="#">
          <img src="../assets/images/viernes_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">29 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 6 - Ends Here  -->
     <!--   ✅ Product card 7 - Starts Here 👇 -->
     <div id="card7" @click="selectCard('card7')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card7' || selectedCard === null">       
     <a href="#">
          <img src="../assets/images/sabado_santo.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">30 de Marzo</span>

<p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
<div class="flex items-center">
    
    <div class="flex justify-end">
      <img src="../assets/images/calendario.png" class="w-1/4 "/>
    </div>
</div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 7 - Ends Here  -->
     <!--   ✅ Product card 8 - Starts Here 👇 -->
     <div id="card8" @click="selectCard('card8')" class="w-72 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl" v-show="selectedCard === 'card8' || selectedCard === null">       
     <a href="#">
          <img src="../assets/images/domingo_resureccion.jpg"
                    alt="Product" class="h-110 w-92 object-cover rounded-t-xl" />
            <div class="px-4 py-3 w-72">
              <span class="text-gray-400 mr-3 uppercase text-xs">31 de Marzo</span>

              <p class="text-lg font-bold text-black truncate block capitalize">Programación del Día</p>
              <div class="flex items-center">
                  
                  <div class="flex justify-end">
                    <img src="../assets/images/calendario.png" class="w-1/4 "/>
                  </div>
              </div>
            </div>
        </a>
    </div>
    <!--   🛑 Product card 8 - Ends Here  -->

</section>
<div v-if="selectedCard !== null" class="col-span-1 md:col-span-3 lg:col-span-3 ">
  <div class="flex flex-col items-center justify-center gap-5 mt-6 md:flex-row" @click="selectedCard = null"> <button class="inline-flex items-center justify-center w-auto min-w-[250px] px-6 py-4 text-white transition-all bg-[#1782C8] rounded-md shadow-xl sm:w-auto hover:bg-gray-900 hover:text-white shadow-slate-300 dark:shadow-slate-700 hover:shadow-2xl hover:shadow-slate-400 hover:-translate-y-px" >
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5-5 5-5v3h5v4h-5v3zm-8-5a10 10 0 1010 10A10 10 0 002 10z"/></svg>
  Volver al Inicio
                  </button>

    </div>

                  <DiaSanto :card-id="selectedCard"/>

                  <div class="flex flex-col items-center justify-center gap-5 mt-6 md:flex-row" @click="selectedCard = null"> <button class="inline-flex items-center justify-center w-auto min-w-[250px] px-6 py-4 text-white transition-all bg-[#1782C8] rounded-md shadow-xl sm:w-auto hover:bg-gray-900 hover:text-white shadow-slate-300 dark:shadow-slate-700 hover:shadow-2xl hover:shadow-slate-400 hover:-translate-y-px" >
  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5-5 5-5v3h5v4h-5v3zm-8-5a10 10 0 1010 10A10 10 0 002 10z"/></svg>
  Volver al Inicio
                  </button>


    </div>
        </div>
<!-- 🛑 Grid Section - Ends Here -->

     

  </div>
</template>

<script>
import DiaSanto from '@/components/DiaSanto.vue';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  components: {
    DiaSanto,
  },
  data() {
    return {
      // Example cards data
      cards: [
       
      ],
      selectedCard: null,
    };
  },
  methods: {
    selectCard(index) {
      console.log('Antes de actualizar:', this.selectedCard);
  this.selectedCard = this.selectedCard === index ? null : index;
  console.log('Después de actualizar:', this.selectedCard);
    },
  },
}
</script>

